<template>
  <div>
    <div
      class="container-toggles h-full max-h-[300px] w-full overflow-y-auto overflow-x-hidden pb-1"
    >
      <div
        :id="`container-toggles-${chartId}`"
        class="flex w-full flex-col flex-nowrap items-start gap-y-3 px-1"
        :class="[
          togglesCenter ? 'justify-center' : 'justify-start',
          { 'pr-2': indexBottomLine !== null },
        ]"
      >
        <Toggle
          v-for="(dataSet, index) in reversedDatasets"
          :key="index"
          prices
          :active="dataSet.isActive"
          :active-color="dataSet.borderColor"
          :data-test="`${chartId}-toggle`"
          :chart-id="chartId"
          :with-bottom-line="indexBottomLine === index"
          @change-toggle="updateDataset(dataSet.label)"
        >
          {{ dataSet.label }}
        </Toggle>
      </div>
    </div>
    <SelectedAllToggle
      :chart-id="chartId"
      :is-all-selected="isAllSelected"
      :can-select-all="canSelectAll"
      @change-all-toogles="changeAllToogles"
    >
      {{ allSelectedText }}
    </SelectedAllToggle>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

const props = withDefaults(
  defineProps<{
    datasets: any[]
    indexHidden: number[]
    chartId?: string
    togglesCenter?: boolean
    indexBottomLine?: number | null
    isAllSelected?: boolean
    hasSelectedAllToogle?: boolean
    deselectChinaToggle?: boolean
    noReverse?: boolean
  }>(),
  {
    chartId: '',
    togglesCenter: false,
    indexBottomLine: null,
    hasSelectedAllToogle: true,
    deselectChinaToggle: false,
    noReverse: false,
  },
)

const emit = defineEmits<{
  updateDataset: [index: number]
  changeAllToogles: []
}>()

const isAllSelected = computed(() => props.indexHidden.length === 0)
const allSelectedText = computed(() => (isAllSelected.value ? 'Deselect all' : 'Select all'))
const reversedDatasets = computed(() => {
  if (props.noReverse) {
    return [...props.datasets].map((dataset, index) => ({
      ...dataset,
      isActive: !props.indexHidden.includes(index),
    }))
  }
  return [...props.datasets]
    .map((dataset, index) => ({
      ...dataset,
      isActive: !props.indexHidden.includes(index),
    }))
    .toReversed()
})

const getOriginalIndex = (label) => {
  return [...props.datasets].map((item) => item.label).indexOf(label)
}

const updateDataset = (label) => {
  const originalIndex = getOriginalIndex(label)
  emit('updateDataset', originalIndex)
}

const canSelectAll = computed(() => props.hasSelectedAllToogle && reversedDatasets.value.length > 3)

const changeAllToogles = () => {
  if (isAllSelected.value) {
    reversedDatasets.value.forEach((data) => {
      updateDataset(data.label)
    })
    return
  }
  reversedDatasets.value.forEach((data) => {
    if (data.isActive) return
    updateDataset(data.label)
  })
}

nextTick(() => {
  if (props.deselectChinaToggle) {
    const chinaToggleIndex = reversedDatasets?.value.findIndex(
      (data) => data.label.toUpperCase() === 'CHINA',
    )
    emit('updateDataset', chinaToggleIndex)
  }
})
</script>
<style>
.container-toggles::-webkit-scrollbar {
  @apply w-1;
}
</style>
